<template>
  <div>
    <time class="header-date-time">
      <strong class="header-date-time__time">{{ currentTime }}</strong>
      <span class="header-date-time__date">{{ currentDate | ucfirst }}</span>
    </time>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      currentTime: '',
      currentDate: '',
    }
  },
  methods: {
    updateDateTime() {
      this.currentTime = moment(new Date()).format("HH:mm:ss");
      this.currentDate = moment(new Date()).format("dddd DD MMMM Y");
    }
  },
  filters: {
    ucfirst: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  created() {
    setInterval(() => this.updateDateTime(), 1000);
  },
}
</script>
