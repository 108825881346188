import { createPopper } from '@popperjs/core';
import bootstrap from 'bootstrap';

import moment from "moment";
moment.locale('nl');

import Vue from 'vue';
import axios from "axios";
Vue.prototype.$http = axios;

Vue.filter('formatDateToTime', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
});


import departuresTable from './vue/components/departuresTable';
import headerDateTime from './vue/components/headerDateTime';


const components = {};

components.HeaderDateTime = () => import('./vue/components/headerDateTime.vue');
components.departuresTable = () => import('./vue/components/departuresTable.vue');
components.shuttles = () => import('./vue/components/shuttles.vue');

const app = new Vue({
    el: '#app',
    components,
});
