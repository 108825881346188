<template>
  <div class="px-2 py-4">

    <div class="alert alert-danger" v-for="error in errors">
      <span>{{ error.message }}</span>
    </div>

    <div class="departures" v-bind:class="{ 'departures--loading' : isLoading }">
      <table class="table table-striped table-borderless m-0">
        <thead>
          <tr>
            <th width="15%" scope="col">Gepland</th>
            <th width="30%" scope="col">Bestemming</th>
            <th width="20%" scope="col">Vluchtnr.</th>
            <th width="35%" scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr class="flight" v-for="flight in flights" v-bind:class="{ 'flight--delayed' : (flight.isDelayed && !flight.isDeparted), 'flight--cancelled' : flight.isCancelled, 'flight--departed' : flight.isDeparted }">
            <td
                scope="row"
                class="scheduled-date"
                v-bind:class="{ 'scheduled-date--delayed' : flight.isDelayed }">
              {{ flight.scheduledDate|formatDateToTime }}
            </td>
            <td class="destination">{{ flight.destination }}</td>
            <td class="name">{{ flight.name }}</td>
            <td class="status">{{ flight.status }}</td>
          </tr>
          <tr v-if="isLoading == false && flights.length == 0">
            <td colspan="4">Geen vluchten beschikbaar</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flights: [],
      errors: [],
      loading: true,
      isLoading: true
    }
  },
  methods: {
    updateFlights() {
      this.$http
          .get('/api/flights/index')
          .then(response => {
            this.isLoading = true;
            this.flights = response.data.flights;
          })
          .catch(error => {
            this.errors = [{ message: error }];
          })
          .finally(() => this.isLoading = false)
    }
  },
  created() {
    this.updateFlights()
    setInterval(() => this.updateFlights(), 60000);
  },
}
</script>
